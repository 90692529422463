<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <div class="row">
              <CCol sm="6" class="align-self-center">
                <strong>{{ $lang.collegeEmployer.crud.view }}</strong>
              </CCol>
            </div>
          </CCardHeader>
          <CCardBody>
            <div class="detail-div">
              <CRow>
                <CCol md="3">
                  <CRow>
                    <CCol md="12" class="text-left">
                      <img alt="Profile Pic" :src="imageURL" height="auto" width="100%"/>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol md="9" class="align-self-center">
                  <CRow>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.collegeEmployer.form.company }}</p>
                      <p class="custome-detail">{{ collegeEmployer.company }}</p>
                    </CCol>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.collegeEmployer.form.email }}</p>
                      <p class="custome-detail">{{ collegeEmployer.email }}</p>
                    </CCol>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.collegeEmployer.form.user }}</p>
                      <p class="custome-detail">{{ collegeEmployer.user.name }}</p>
                    </CCol>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.collegeEmployer.form.college }}</p>
                      <p class="custome-detail">{{ collegeEmployer.college.name }}</p>
                    </CCol>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.collegeEmployer.form.mobile_number }}</p>
                      <p class="custome-detail">{{ collegeEmployer.countryCode }} {{ collegeEmployer.mobile_number }}</p>
                    </CCol>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.collegeEmployer.form.website }}</p>
                      <p class="custome-detail">{{ collegeEmployer.website }}</p>
                    </CCol>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.collegeEmployer.form.address1 }}</p>
                      <p class="custome-detail">{{ collegeEmployer.address1 }}</p>
                    </CCol>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.collegeEmployer.form.country }}</p>
                      <p class="custome-detail">{{ collegeEmployer.country.name }}</p>
                    </CCol>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.collegeEmployer.form.state }}</p>
                      <p class="custome-detail">{{ collegeEmployer.state.name }}</p>
                    </CCol>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.collegeEmployer.form.city }}</p>
                      <p class="custome-detail">{{ collegeEmployer.city.name }}</p>
                    </CCol>
                    <CCol sm="6" md="4" lg="4">
                      <p class="custome-labal">{{ $lang.collegeEmployer.form.isActive }}</p>
                      <p class="custome-detail" v-if="collegeEmployer.isActive == 0">No</p>
                      <p class="custome-detail" v-if="collegeEmployer.isActive == 1">Yes</p>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </div>
            <hr>
            <h3>Contact Person Detail</h3>
            <div id="people" style="padding-top: 10px;">
              <v-server-table
                  :columns="columns"
                  :url="listUrl"
                  :options="options"
                  ref="myTable"
                  @loaded="exports(self, 'Excel', module, 'College Employer Staff', self.editId)">
                <template #title="data">
                  {{ data.row.title.title }}
                </template>
                <template #username="data">
                  {{ data.row.countryCode }} {{ data.row.username }}
                </template>
              </v-server-table>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import store from "/src/store/store.js";
import {staff} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {serverBus} from "../../main";
Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
export default {
  name: "CollegeEmployers",
  mixins: [Mixin],
  data() {
    return {
      json_data: [],
      json_label: {},
      json_title: "",
      imageURL: "",
      imageRemove: "",
      FILE: null,
      existImage: false,
      imageOutput: "",
      saving: false,
      saved: false,
      adminId: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      self: this,
      msg: "",
      items: [],
      activePage: 1,
      module: staff,
      avatar: {
        imageURl: "",
      },
      columns: ["firstName","title","email","username"],
      data: [],
      options: {
        headings: {
          name: this.$lang.collegeEmployer.table.name,
          firstName: this.$lang.collegeEmployer.table.firstName,
          title: this.$lang.collegeEmployer.table.title,
          username: this.$lang.collegeEmployer.table.username,
        },
        editableColumns: [],
        sortable: [],
        filterable: [],
      },
      collegeEmployer: {
        company: "",
        email: "",
        imageURL: "",
        user: "",
        college: "",
        mobile_number: "",
        website: "",
        address1: "",
        countryCode: "",
        country: "",
        state: "",
        city: "",
        isActive: "",
      },
    };
  },
  created() {
    let self = this;
    const id = this.$route.params.id;
    self.listUrl = "college/employer/staff/" + id;
  },
  mounted() {
    let self = this;
    self.dismissCountDown = 0;
    localStorage.setItem("showAlert", "");
    self.dismissCountDown = 0;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    self.editId = id;
    axios
        .get('college/employer/'+self.editId)
        .then((response) => {
          const responseData = response.data.data;
          self.collegeEmployer.company = responseData.company;
          self.collegeEmployer.email = responseData.email;
          self.collegeEmployer.user = responseData.user;
          self.collegeEmployer.college = responseData.college;
          self.collegeEmployer.mobile_number = responseData.mobile_number;
          self.collegeEmployer.website = responseData.website;
          self.collegeEmployer.address1 = responseData.address1;
          self.collegeEmployer.countryCode = responseData.countryCode;
          self.collegeEmployer.country = responseData.country;
          self.collegeEmployer.state = responseData.state;
          self.collegeEmployer.city = responseData.city;
          self.collegeEmployer.isActive = responseData.isActive;
          self.imageURL = responseData.logo;
          if (responseData.imageUrl !== "") {
            serverBus.$emit("send_cropper_image_url", responseData.logo);
            self.existImage = true;
          }
          store.commit("showLoader", false); // Loader Off
        })
        .catch(function (error) {
          store.commit("showLoader", false); // Loader Off
        });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
    avatar: {
      handler: function () {
        this.saved = false;
      },
      deep: true,
    },
  },
  methods: {},
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
